.contactForm {
    display: flex;
    flex-direction: column;
    z-index: 10;
    width: 30%;
}

.field {
    display: flex;
    width: fit-content;
    color: var(--terminalTabFocusColor);
    & label {
        width: 5rem;
        color: var(--terminalTabFocusColor);

    }

    & input,
    & textarea {
        color: var(--stringColor);
        border: none;
        background-color: transparent;
        box-sizing: border-box;
        resize: none;
        width: 30vh;
        outline: none;
        
    }
}

.submit {

    & input {
        border: solid var(--leftLineColor) 1.5px;
        border-radius: 5px;
        background-color: transparent;
        outline: none;
        color: var(--terminalTabFocusColor);

    }
}


@media only screen and (max-width: 1340px) {

    .contactForm {
        display: flex;
        flex-direction: column;
        row-gap: 0;
        width: 10%;
    }

    .field {
        row-gap: 0;

        & label {
            width: 3rem;
            font-size: 0.8rem;
        }

        & input,
        & textarea {
            border: none;
            background-color: transparent;
            box-sizing: border-box;
            resize: none;
            width: auto;
            outline: none;
        }
    }

    .submit {
        & input {
            font-size: 0.6rem;
        }
    }
}