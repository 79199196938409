.skills {
    display: block;
}

.skillList {
    display: flex;
    /* justify-content: space-evenly; */
    gap: 10rem;
}

/* .skillCard {} */


.subject {
    position: relative;
    z-index: 2;
    display: inline-block;
    width: fit-content;
    color: var(--varColor);
}



.subject:after {
    position: absolute;
    bottom: 4px;
    content: '';
    width: 2.2px;
    height: 1.4rem;
    margin-left: 2.35px;
    background: var(--lineNumbersColor);
    opacity: 0;
    display: inline-block;
    animation: flash 1s linear infinite;
}

@keyframes flash {
    0% {
        opacity: 1;
    }

    49% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 0;
    }
}

.stack {
    background-color: var(--tabColor);
    position: relative;
    left: 5rem;
    display: flex;
    flex-direction: column;
    border: solid var(--tabBorderColor);
    border-width: 0.1rem 0.1rem 0 0.1rem;
    border-radius: 3px;
}


.skillItem {
    cursor: pointer;
    z-index: 2;
    display: flex;
    align-items: center;
    padding-right: 9rem;
    height: 1.5rem;


    >i {
        font-size: 0.97rem !important;
        color: var(--skillIconColor);
        margin-left: 0.1rem;
    }

    &:first-child,
    &:first-child:hover {
        background-color: var(--skillItemBackgroundColor);
        /* color: #fff; */
    }

    &:last-child {
        border: solid var(--tabBorderColor);
        height: 1.42rem;
        border-width: 0 0 0.1rem 0;
    }

    &:hover {
        background-color: var(--skillItemHoverColor);
    }
}

.skills > ul > div:nth-child(1) > ul {
    left: 11.15rem;

    & .skillItem {
        padding-right: 5rem;
    }
}


.skills > ul > div:nth-child(1) {
    padding-right: 6rem;
}


.icon {
    width: 1.5rem;
    height: 1.4rem;
    display: flex;
    align-items: center;
}

.skillName {
    margin-left: -0.2rem;
}


@media only screen and (max-width: 1340px) {
    .skills {
        display: flex;
        flex-direction: column;
        width: 60%;
    }

    .skillList {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
    }

    .skillItem {
        padding-right: 0;
    }

    .stack {
        left: 5rem;
    }

    .subject {
        position: relative;
        z-index: 2;
        display: inline-block;
        width: 50vw;
        color: var(--varColor);
    }
    
    .subject:after {
        position: absolute;
        bottom: 4px;
        content: '';
        width: 2.2px;
        height: 1.4rem;
        margin-left: 2.35px;
        background: var(--lineNumbersColor);
        opacity: 0;
        display: inline-block;
        animation: flash 1s linear infinite;
    }

    .skills > ul > div:nth-child(1) > ul {
        left: 11rem;
        width: 65%;

        & .skillItem {
            padding-right: 0;
        }
    }
    
    
    .skills > ul > div:nth-child(1) {
        padding-right: 0;
    }
    
}