/* :root {
    --moonColor: #1f1f1f;
    --moonBackground: #fff;
    --page-color-transition: all .5s ease, color .5s ease;
} */

[data-theme="dark"] {
    --moonColor: #f9d81b;
    --moonBackground: #1f1f1f;
}

.themeToggle {
    z-index: 3;
    cursor: pointer;
    display: flex;
    width: 1rem;
    height: 1.5rem;
    padding: 1rem 0.5rem;
}

#darkmode {
    padding: 0.3rem 0.5rem;
    cursor: pointer;
    width: 2.5rem;
    height: 2.5rem;
    position: absolute;
    margin: auto;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: scale(3);
}

.darkmode_icon {
    position: absolute;
    width: 1.2rem;
    height: 1.2rem;
    border-radius: 0.6rem;
    background: var(--moonColor);
    transform-origin: center center;
    transition: transform 0.75s ease-in-out, var(--page-color-transition);
}

.darkmode_icon::after {
    position: absolute;
    content: '';
    width: 1rem;
    height: 1rem;
    left: 0.5rem;
    bottom: 0.4rem;
    border-radius: 0.6rem;
    background: var(--moonBackground);
    transform-origin: center center;
    transition: transform 0.5s ease, left 0.25s ease, bottom 0.25s ease, var(--page-color-transition);
}

.darkmode_icon .ray {
    position: absolute;
    left: 0.385rem;
    top: 0.385rem;
    width: 0.45rem;
    height: 0.45rem;
    border-radius: 0.9rem;
    background: var(--moonColor);
    transform-origin: center;
    transition: transform 0.5s ease-in-out, var(--page-color-transition);
}

.ray:nth-child(1) {
    transform: rotate(45deg) translateX(0);
}

.ray:nth-child(2) {
    transform: rotate(90deg) translateX(0);
}

.ray:nth-child(3) {
    transform: rotate(135deg) translateX(0);
}

.ray:nth-child(4) {
    transform: rotate(180deg) translateX(0);
}

.ray:nth-child(5) {
    transform: rotate(225deg) translateX(0);
}

.ray:nth-child(6) {
    transform: rotate(270deg) translateX(0);
}

.ray:nth-child(7) {
    transform: rotate(315deg) translateX(0);
}

.ray:nth-child(8) {
    transform: rotate(360deg) translateX(0);
}

[data-theme="dark"] {
    & .darkmode_icon {
        transform: scale(0.6);
    }

    & .darkmode_icon::after {
        left: 1rem;
        bottom: 0.5rem;
        transform: scale(0);
    }

    & .ray:nth-child(1) {
        transform: rotate(45deg) translateX(-16px);
    }

    & .ray:nth-child(2) {
        transform: rotate(90deg) translateX(-16px);
    }

    & .ray:nth-child(3) {
        transform: rotate(135deg) translateX(-16px);
    }

    & .ray:nth-child(4) {
        transform: rotate(180deg) translateX(-16px);
    }

    & .ray:nth-child(5) {
        transform: rotate(225deg) translateX(-16px);
    }

    & .ray:nth-child(6) {
        transform: rotate(270deg) translateX(-16px);
    }

    & .ray:nth-child(7) {
        transform: rotate(315deg) translateX(-16px);
    }

    & .ray:nth-child(8) {
        transform: rotate(360deg) translateX(-16px);
    }
}



@media only screen and (max-width: 600px) {
    .themeToggle {
        z-index: 3;
        cursor: pointer;
        display: flex;
        width: 1rem;
        height: 1rem;
        margin: 0;
        padding: 0.3rem 0.5rem 0.8rem 0.4rem;
    }


}