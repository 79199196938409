footer {
    z-index: 10;
    display: grid;
    grid-template-rows: 0.7rem auto;
    grid-template-areas:
        'resizer'
        'container';

    position: fixed;
    top: 85vh;
    width: 100%;
    height: 100%;
    margin: 0 -10rem;
    background-color: var(--pageBackgroung);
    /* padding-top: 0.7rem; */
    /* transition: all 0.4s; */

    &.expand {
        top: 17% !important;
    }

    .resizer {
        grid-area: resizer;
        padding-bottom: 0.7rem;
        border-top: solid var(--leftLineColor) 1.5px;
        animation: flash 4s ease-in-out forwards;
    }
}

.container {
    grid-area: container;
    display: grid;
    grid-template-columns: 10fr 1fr;

    grid-template-areas:
        'footerContent terminalButtons';
}



.footerContent {
    grid-area: footerContent;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    margin: 0 2rem 0 5rem;


    & span {
        display: flex;
        column-gap: 2rem;
    }
}

.footerNav {
    display: flex;
    justify-content: space-between;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;

}

.tabs {
    display: flex;
    color: var(--terminalTabsColor);
    letter-spacing: -0.1px;
    font-size: 0.87rem;
    text-shadow: 0.3px 0px 0px var(--terminalTabsColor);
    column-gap: 1.8rem;
}

.tab:hover {
    color: var(--terminalTabFocusColor);
}

.active {
    color: var(--terminalTabFocusColor);
    border-bottom: solid var(--terminalTabFocusColor) 1.5px;
    padding: 0 2px 1px 2px;
}

.leaveMessage {
    display: flex;
    width: 100%;
    column-gap: 0.5rem;
    font-size: 1.04rem;
    margin-top: 0.2rem;
    /* transition: all 0.5s; */
}


.slide-up {
    transition: all 1s ease-out;
    max-height: 1000px;
    overflow: hidden;
}

.slide-down {
    transition: all 1s ease-in;
    overflow: hidden;
}

.slide-up-hidden {
    max-height: 0;
}





.name {
    font-weight: bolder;
    color: #24b8db;
}

.message {
    color: #16bc79;
}

.currentTime,
.path {
    color: var(--terminalTabsColor);
}

.fileUpdated {
    color: var(--logoNameColor);
}


.contact {
    display: flex;
    position: relative;

}

.terminalButtons {
    grid-area: terminalButtons;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    max-height: 2rem;
    margin: 0 5rem 0 5rem;
    column-gap: 0.3rem;

    & span {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 2rem;
        min-height: 2rem;

    }

    & span:first-child {
        display: flex;
        align-items: center;
        font-size: 0.8rem;
        column-gap: 3px;
        padding: 0 5px;
    }

    & span:nth-child(2) {
        column-gap: 0px;

        &i:first-child {
            position: relative;
            left: 4px;
        }

        &i:nth-child(2) {
            scale: 0.7;
        }
    }

    & span:hover {
        border-radius: 8px;
        background-color: #ffffff10;
        cursor: pointer;
    }

}

.resizeAlert {
    filter: var(--resizeAlertColor);
    position: absolute;
    width: 100%;

    i {
        position: absolute;
        scale: 3;
        margin: 0 50%;
        -webkit-filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, .7));
        filter: drop-shadow(0px 0px 3px rgba(0, 0, 0, .9));
    }

    animation: flash-hide 4s ease-in-out forwards;
}

@keyframes flash {

    0%,
    50%,
    100% {
        opacity: 1;
        visibility: visible;
    }

    25%,
    75% {
        opacity: 0;
    }
}

@keyframes flash-hide {

    0%,
    50%,
    90% {
        opacity: 1;
        visibility: visible;
    }

    25%,
    75%,
    100% {
        opacity: 0;
        visibility: hidden;
    }
}




@media only screen and (max-width: 1340px) {
    footer {
        top: 80vh;
        margin: 0 -3rem;
        transition: all 0.4s;

        & .resizer {
            animation: none;
        }
    }

    .resizeAlert {
        display: none;
    }
    


    .footerContent {
        margin: 0 1rem;
        row-gap: 0.5rem;
    }

    .tabs {
        font-size: 0.7rem;
        column-gap: 1rem;
    }

    .leaveMessage {
        font-size: 0.64rem;
        margin-top: 0.4rem;
    }

    .message {
        min-width: fit-content;
    }

    .footerNav {
        display: flex;
    }

    .terminalButtons {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-end;

        margin: 0 0.7rem 0 0rem;

        & span {
            scale: 0.9;
            min-width: 1.5rem;
            min-height: 1.5rem;
        }
    }



    .contact {
        flex-direction: column;
        row-gap: 0.5rem;
    }
}


@media only screen and (max-width: 380px) {
    footer {
        top: 80vh;
        margin: 0 -3rem;
    }

    .leaveMessage {
        font-size: 0.6rem;
        margin-top: 0.4rem;
    }

    .tabs {
        font-size: 0.6rem;
        column-gap: 1rem;
    }
}










i[class$="bash"]:before {
    content: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='18' focusable='false' viewBox='0 0 12 12'%3E%3Cg fill='none' stroke='currentColor'%3E%3Crect width='11' height='9' x='.5' y='1.5' rx='.5' ry='.5'/%3E%3Cpath stroke-linecap='round' d='M5.5 8.5h4m-7-5l2.1 2.1a.5.5 0 010 .7L2.5 8.5'/%3E%3C/g%3E%3C/svg%3E");
    filter: var(--terminalButtonsColor);
}

i[class$="plus"]:before {
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='18' focusable='false' viewBox='0 0 256 230'%3E%3Cpath fill='%23000' d='M222 128a6 6 0 0 1-6 6h-82v82a6 6 0 0 1-12 0v-82H40a6 6 0 0 1 0-12h82V40a6 6 0 0 1 12 0v82h82a6 6 0 0 1 6 6'/%3E%3C/svg%3E");
    filter: var(--terminalButtonsColor);
}

i[class$="arrow-up"]:before {
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='13' viewBox='0 0 1024 1024'%3E%3Cpath fill='%23000' d='M8.2 751.4c0 8.6 3.4 17.401 10 24.001c13.2 13.2 34.8 13.2 48 0l451.8-451.8l445.2 445.2c13.2 13.2 34.8 13.2 48 0s13.2-34.8 0-48L542 251.401c-13.2-13.2-34.8-13.2-48 0l-475.8 475.8c-6.8 6.8-10 15.4-10 24.2z'/%3E%3C/svg%3E");
    filter: var(--terminalButtonsColor);
}


i[class$="arrow-down"]:before {
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='18' viewBox='0 0 1024 1024'%3E%3Cpath fill='%23000' d='M831.872 340.864L512 652.672L192.128 340.864a30.59 30.59 0 0 0-42.752 0a29.12 29.12 0 0 0 0 41.6L489.664 714.24a32 32 0 0 0 44.672 0l340.288-331.712a29.12 29.12 0 0 0 0-41.728a30.59 30.59 0 0 0-42.752 0z'/%3E%3C/svg%3E");
    filter: var(--terminalButtonsColor);
}


i[class$="trash"]:before {
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='24' viewBox='0 0 56 46'%3E%3Cpath fill='%23000' d='m44.524 48.66l1.617-34.265h2.343c.961 0 1.735-.797 1.735-1.758s-.774-1.782-1.735-1.782H38.242V7.34c0-3.352-2.273-5.531-5.882-5.531h-8.766c-3.61 0-5.86 2.18-5.86 5.53v3.516H7.54c-.937 0-1.758.82-1.758 1.782c0 .96.82 1.758 1.758 1.758h2.344L11.5 48.684c.164 3.375 2.39 5.507 5.766 5.507h21.492c3.351 0 5.601-2.156 5.765-5.53M21.484 7.574c0-1.336.985-2.273 2.391-2.273h8.227c1.43 0 2.414.937 2.414 2.273v3.281H21.484Zm-3.867 43.102c-1.36 0-2.367-1.032-2.437-2.39l-1.64-33.891h28.85l-1.546 33.89c-.07 1.383-1.055 2.39-2.438 2.39Zm17.344-4.125c.773 0 1.36-.633 1.383-1.524l.703-24.75c.023-.89-.586-1.547-1.383-1.547c-.726 0-1.336.68-1.36 1.524l-.702 24.773c-.024.844.562 1.524 1.359 1.524m-13.898 0c.797 0 1.382-.68 1.359-1.524l-.703-24.773c-.024-.844-.656-1.524-1.383-1.524c-.797 0-1.383.657-1.36 1.547l.727 24.75c.024.891.586 1.524 1.36 1.524m8.367-1.524V20.254c0-.844-.633-1.524-1.407-1.524c-.773 0-1.43.68-1.43 1.524v24.773c0 .844.657 1.524 1.43 1.524c.75 0 1.407-.68 1.407-1.524'/%3E%3C/svg%3E");
    filter: var(--terminalButtonsColor);
}


i[class$="close"]:before {
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' focusable='false' viewBox='0 0 32 24'%3E%3Cpath fill='%23000' d='M17.414 16L24 9.414L22.586 8L16 14.586L9.414 8L8 9.414L14.586 16L8 22.586L9.414 24L16 17.414L22.586 24L24 22.586z'/%3E%3C/svg%3E");
    filter: var(--terminalButtonsColor);
}




i[class$="resize"]:before {
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1rem' height='1rem' viewBox='0 0 56 56'%3E%3Cpath fill='%23000' fill-rule='evenodd' d='M30.2 9.828v36.344l3.757-3.758a2 2 0 0 1 2.828 2.829l-7.07 7.07a2.036 2.036 0 0 1-.052.05a1.995 1.995 0 0 1-1.464.637c-.493 0-.944-.178-1.293-.474a2.014 2.014 0 0 1-.25-.212l-7.07-7.071a2 2 0 1 1 2.828-2.829L26.2 46.2V9.801l-3.785 3.785a2 2 0 1 1-2.828-2.829l7.07-7.07c.08-.08.163-.15.25-.213c.349-.296.8-.474 1.293-.474c.578 0 1.099.245 1.464.637l.051.05l7.071 7.07a2 2 0 1 1-2.828 2.829z'/%3E%3C/svg%3E");
    /* filter: var(--terminalButtonsColor); */
}